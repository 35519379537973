import(/* webpackMode: "eager" */ "/home/node/app/node_modules/.pnpm/next@14.2.5_patch_hash=my5x2f5kgra5bdej4xv3mcvvaa_@babel+core@7.25.2_@opentelemetry+api@1.8.0_bxgtt2f2ldpmciqru3msku2kl4/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/home/node/app/node_modules/.pnpm/next@14.2.5_patch_hash=my5x2f5kgra5bdej4xv3mcvvaa_@babel+core@7.25.2_@opentelemetry+api@1.8.0_bxgtt2f2ldpmciqru3msku2kl4/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/node/app/node_modules/.pnpm/next@14.2.5_patch_hash=my5x2f5kgra5bdej4xv3mcvvaa_@babel+core@7.25.2_@opentelemetry+api@1.8.0_bxgtt2f2ldpmciqru3msku2kl4/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/node/app/node_modules/.pnpm/next@14.2.5_patch_hash=my5x2f5kgra5bdej4xv3mcvvaa_@babel+core@7.25.2_@opentelemetry+api@1.8.0_bxgtt2f2ldpmciqru3msku2kl4/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/node/app/node_modules/.pnpm/next@14.2.5_patch_hash=my5x2f5kgra5bdej4xv3mcvvaa_@babel+core@7.25.2_@opentelemetry+api@1.8.0_bxgtt2f2ldpmciqru3msku2kl4/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/node/app/node_modules/.pnpm/next@14.2.5_patch_hash=my5x2f5kgra5bdej4xv3mcvvaa_@babel+core@7.25.2_@opentelemetry+api@1.8.0_bxgtt2f2ldpmciqru3msku2kl4/node_modules/next/dist/client/components/render-from-template-context.js");
